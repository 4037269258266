/* Modal Container */
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem; /* Equivalent to p-6 */
  background-color: #142028; /* bg-gray-50 */
  height: 100%; /* h-full */
}

/* Fixed Modal */
.fixedModal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modalContent {
  background-color: #142028; /* bg-white */
  border-radius: 1rem; /* rounded-2xl */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* shadow-2xl */
  padding: 2rem; /* p-8 */
  width: 90%; /* w-[90%] */
  max-width: 500px; /* max-w-[500px] */
  margin: auto; /* mx-auto */
  height: 90%; /* h-[90%] */
  max-height: 90%; /* max-h-[90%] */
  overflow-y: auto; /* overflow-y-auto */
}

/* Header Styles */
.modalHeader {
  font-size: 1.875rem; /* text-3xl */
  font-weight: bold; /* font-bold */
  text-align: center; /* text-center */
  color: white; /* text-gray-900 */
}

/* Text Styles */
.modalText {
  font-size: 1.125rem; /* text-lg */
  text-align: center; /* text-center */
  color: #fcfcfc; /* text-gray-700 */
}

/* Button Styles */
.button {
  width: 100%; /* w-full */
  padding: 0.5rem 1.25rem; /* px-5 py-2 */
  font-size: 1.125rem; /* text-lg */
  border-radius: 0.5rem; /* rounded-lg */
  transition: all 0.3s ease; /* transition-all */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-md */
}

/* Disconnect Button */
.disconnectButton {
  background-color: #e3342f; /* bg-red-600 */
  color: white; /* text-white */
  margin: 1rem 0;
}

.disconnectButton:hover {
  background-color: #cc2a24; /* hover:bg-red-700 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* hover:shadow-lg */
}

/* Close Button */
.closeButton {
  background-color: #d1d5db; /* bg-gray-300 */
  color: #374151; /* text-gray-700 */
}

.closeButton:hover {
  background-color: #bfbfbf; /* hover:bg-gray-400 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* hover:shadow-lg */
}

/* Wallet Selection Button */
.walletButton {
  width: 100%; /* w-full */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem; /* px-5 py-3 */
  font-size: 1.125rem; /* text-lg */
  border-radius: 0.5rem; /* rounded-lg */
  background: linear-gradient(
    to right,
    #2563eb,
    #4f46e5
  ); /* bg-gradient-to-r from-blue-600 to-indigo-600 */
  color: white; /* text-white */
  transition: all 0.3s ease; /* transition-all */
}

.walletButton:hover {
  background: linear-gradient(
    to right,
    #1e40af,
    #4c51bf
  ); /* hover:from-blue-700 hover:to-indigo-700 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* hover:shadow-lg */
  transform: scale(1.05); /* hover:scale-105 */
}

/* Copy Icon */
.copyIcon {
  cursor: pointer; /* cursor-pointer */
  transition: color 0.2s; /* transition-colors */
}

.copyIcon:hover {
  color: #4b5563; /* hover:text-gray-700 */
}
